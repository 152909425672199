import { OrgUserModel } from '@cibo/core'
import { UserSelectionDisplay } from '@cibo/ui'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Autocomplete, FormControl, FormLabel, TextField, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOrgUser, usePaginatedOrgUsers } from '../../queries'
import { trackEvent } from '../../utils'
import { AssignUserControlProps } from './UserControlTypes'

export const AssignUserControl = ({
  disabled,
  hideLabel,
  inputLabelProps,
  owningOrgId,
  persona = ['grower'],
  readOnly,
  required,
  setUserId,
  textFieldProps,
  userId,
  disabledUserIds,
  'data-testid': dataTestId,
}: AssignUserControlProps) => {
  const { t } = useTranslation('@cibo/profile/AssignUserControl')
  const [inputValue, setInputValue] = useState<string>()

  const paginatedUsersQuery = usePaginatedOrgUsers({
    isActive: true,
    persona,
    fullText: inputValue,
    orgId: owningOrgId,
  })
  const searchResults = useMemo(
    () => paginatedUsersQuery.data?.items.map(user => new OrgUserModel(user)),
    [paginatedUsersQuery.dataUpdatedAt]
  )
  const user = useOrgUser(userId)

  const value = useMemo(() => {
    return user.data
  }, [user.data?.userId, user.dataUpdatedAt])

  return (
    <FormControl required={required} sx={{ width: '100%' }}>
      {!hideLabel && <FormLabel {...inputLabelProps}>{t('user')}</FormLabel>}
      {!!readOnly ? (
        <Typography>{user.data?.displayNameFull || t('none')}</Typography>
      ) : !!disabled || user.isFetching ? (
        <TextField
          disabled
          data-testid={dataTestId || 'assign-user-control'}
          label={!user.data?.displayNameFull && t('user')}
          value={user.data?.displayNameFull}
          slotProps={{
            input: {
              endAdornment: <ArrowDropDownIcon />,
              autoComplete: 'new-password', // disable autocomplete and autofill
            },
          }}
          {...textFieldProps}
        />
      ) : (
        <Autocomplete<OrgUserModel>
          autoHighlight
          fullWidth
          id="user-select"
          data-testid={dataTestId || 'assign-user-control'}
          disabled={user.isFetching}
          loading={paginatedUsersQuery.isPending || user.isFetching}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          getOptionDisabled={option => !!disabledUserIds?.includes(option.userId)}
          options={searchResults || ([] as OrgUserModel[])} // paginatedUsers??
          getOptionLabel={option => option?.displayNameFull || ''}
          isOptionEqualToValue={(option, value) => {
            return option.user.userId === value?.user?.userId
          }}
          // https://mui.com/material-ui/react-autocomplete/#search-as-you-type
          filterOptions={x => x}
          renderOption={(props, option: OrgUserModel) => (
            <UserSelectionDisplay option={option} {...props} key={option.userId} />
          )}
          value={value}
          onChange={(event, newValue) => {
            setUserId(newValue?.user?.userId, newValue?.user)
            trackEvent(
              { name: 'global_user_filter', params: ['filter_user'] },
              { filter_user: newValue?.user?.userId }
            )
          }}
          renderInput={params => {
            return (
              <TextField
                data-testid="searchForUsers"
                placeholder={textFieldProps?.placeholder || t('searchForUsers')}
                {...params}
                {...textFieldProps}
              />
            )
          }}
          inputValue={inputValue || ''}
          onInputChange={(e, v) => {
            setInputValue(v)
          }}
        />
      )}
    </FormControl>
  )
}
