import {
  MpxResponseBaseSingle,
  MpxResponseError,
  OrgUserModel,
  OrgUsersAPI,
  OrgUserSearchRequest,
  OrgUserSearchResult,
  OrgUsersInviteRequest,
  OrgUsersInviteResponse,
  OrgUserUpdate,
  UserId,
} from '@cibo/core'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { useAuth } from '../hooks/useAuth'

export const usePaginatedOrgUsers = (
  request: OrgUserSearchRequest,
  options?: { refetchOnMount?: 'always' }
) => {
  const { isLoggedIn } = useAuth()

  return useQuery({
    queryKey: ['org_users', JSON.stringify(request)],
    queryFn: () => OrgUsersAPI.paginatedUsers(request),
    enabled: isLoggedIn,
    ...options,
  })
}

export const useOrgUser = (userId?: UserId | string, options?: { refetchOnMount?: 'always' }) =>
  useQuery({
    queryKey: ['org_users', userId],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => OrgUsersAPI.user(typeof userId === 'number' ? userId : parseInt(userId!)),
    select: (response: MpxResponseBaseSingle<OrgUserSearchResult>) =>
      new OrgUserModel(response.item),
    enabled: !!userId,
    ...options,
  })

export const useUpdateOrgUser = () => {
  const queryClient = useQueryClient()

  return useMutation<
    MpxResponseBaseSingle<OrgUserSearchResult>,
    AxiosError<MpxResponseError>,
    OrgUserUpdate
  >({
    mutationFn: (payload: OrgUserUpdate) => OrgUsersAPI.patchUser(payload),
    onSuccess: data => queryClient.invalidateQueries({ queryKey: ['org_users', data.item.userId] }),
  })
}

export const useCreateOrgUser = () => {
  const queryClient = useQueryClient()
  return useMutation<
    AxiosResponse<MpxResponseBaseSingle<OrgUserSearchResult>>,
    AxiosError<MpxResponseError>,
    OrgUserUpdate
  >({
    mutationFn: (payload: OrgUserUpdate) => OrgUsersAPI.createUser(payload),
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: ['org_users'] })
    },
  })
}

export const useSendInvite = () => {
  const queryClient = useQueryClient()
  return useMutation<
    AxiosResponse<OrgUsersInviteResponse>,
    AxiosError<MpxResponseError>,
    OrgUsersInviteRequest
  >({
    mutationFn: (payload: OrgUsersInviteRequest) => OrgUsersAPI.sendInvite(payload),
    onSuccess(response, variables, context) {
      queryClient.invalidateQueries({ queryKey: ['org_users', response.data.userId] })
    },
  })
}

export type GrantUserACLArgs = { userId: UserId; existingUserEmail: string }
